module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Mobile Room","short_name":"Mobile Room","start_url":"/","background_color":"#fff","theme_color":"#004D88","display":"standalone","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ab5467355b6c4394176ef882ff9fb1d5"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://themobileroom.com","stripQueryString":true},
    },{
      plugin: require('../../../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../../../packages/gatsby-theme-seomass/gatsby-browser.js'),
      options: {"plugins":[],"siteMetadata":{"siteTitle":"Mobile Room","siteDescription":"Compare the top mobiles!","siteUrl":"https://themobileroom.com","siteSubject":"Mobiles","siteSubjectSlug":"mobiles","siteLanguage":"en","siteImage":"/banner.jpg","siteTheme":{"mainColor":"#004D88","secondColor":"#219CF7","thirdColor":"#044f87"},"siteNavigation":[{"name":"Mobiles","slug":"/mobiles/","submenu":"categories"},{"name":"Blog","slug":"/blog/"},{"name":"About","slug":"/about/","isCustomPage":true,"staticSubMenu":[{"name":"Contact us","slug":"/contact/"}]}]}},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-windicss/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
